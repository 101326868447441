import React, { FC } from "react";
import moment from "moment";

import useAddPageView from "../../hooks/useAddPageView";
import { useAppSelector } from "../../store/hooks";

import "./Confirmation.css";

import useCourses from "../../hooks/useCourses";
import useStyles from "../../hooks/useStyles";

import Banner from "../BasicForm/components/Banner";
import { Button } from "@mui/material";

const Confirmation: FC = () => {
  const __DEV__ = process.env.NODE_ENV === "development";
  useAddPageView(!__DEV__);
  const { styles } = useStyles();

  const { getCampus, getShift } = useCourses();

  const signUpData = useAppSelector((state) => state.signUp).signUp;

  if (!signUpData) {
    return <div style={styles.container}>Erro ao carregar dados</div>;
  }

  const isSchool = signUpData.mode === "school";

  const getType = () => {
    switch (signUpData.mode) {
      case "school":
        return "Desafio Cultural";
      case "ead-eja":
        return "EJA";
      case "ead-post":
      case "unitec-ead-post":
        return "EAD Pós Graduação";
      case "ead-college":
      case "unitec-ead-college":
      case "uniten-ead-college":
        return "EAD Graduação";
      case "post":
        return "Pós Graduação";
      case "technical":
        return "Curso Técnico";
      default:
        return "Graduação";
    }
  };

  return (
    <div style={styles.screen}>
      <Banner mode={signUpData.mode} />
      <div style={styles.blueContainer}>
        <div style={styles.container}>
          {isSchool && (
            <div>
              <h4>
                Agradecemos a inscrição de seu filho(a) no {getType()}
                2023.
              </h4>
              <p>
                Temos mais de 50 anos de história, baseados em uma Educação
                Transformadora, Digital e Humanizada.
              </p>
              <p>Estamos felizes por escolher o Grupo Educacional Drummond.</p>
              <p>Esperamos por Você.</p>
              <h4>Até breve ; )</h4>
            </div>
          )}
          {!isSchool && (
            <div>
              <h4>Agradecemos sua inscrição no VESTIBULAR SOCIAL 2024.</h4>
              <p>
                Temos mais de 50 anos de história, baseados em uma Educação
                Transformadora, Digital e Humanizada.
              </p>
              <p>
                Temos certeza que sua decisão foi motivada pelo desejo de
                conquistar sonhos e superar desafios.
              </p>
              {signUpData.isOnline && (
                <p>
                  Você optou por fazer a prova online. Você pode iniciá-la agora{" "}
                  <a
                    href={
                      signUpData.mode === "technical"
                        ? "https://candidatos-drummond.web.app/"
                        : `/redaction?signUp=${signUpData.id}`
                    }
                  >
                    <b>CLICANDO AQUI</b>
                  </a>
                  . Você também receberá o link no seu e-mail.
                </p>
              )}
            </div>
          )}
          <Button
            variant="contained"
            style={{ ...styles.button, ...styles.fullWidth }}
            onClick={() => (window.location.href = "https://drummond.com.br")}
            color="secondary"
          >
            Ir para a Home
          </Button>
          <h4>Dados da sua inscrição</h4>
          <table>
            <tbody>
              {!isSchool && signUpData.courseCampus && (
                <tr>
                  <td>Curso</td>
                  <td>{signUpData.courseCampus.name}</td>
                </tr>
              )}
              {!isSchool && signUpData.courseCampus && (
                <tr>
                  <td>Campus</td>
                  <td>{getCampus(signUpData.courseCampus.campusID)?.name}</td>
                </tr>
              )}
              {!isSchool && signUpData.courseCampus && (
                <tr>
                  <td>Período</td>
                  <td>{getShift(signUpData.courseCampus.shift)?.name}</td>
                </tr>
              )}
              {signUpData.ingressMode && (
                <tr>
                  <td>Período</td>
                  <td>{signUpData.ingressMode}</td>
                </tr>
              )}
              <tr>
                <td>Nome</td>
                <td>{signUpData.name}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>{signUpData.email}</td>
              </tr>
              <tr>
                <td>Telefone</td>
                <td>{signUpData.phone}</td>
              </tr>
              {!isSchool && (
                <tr>
                  <td>Sexo</td>
                  <td>{signUpData.sex}</td>
                </tr>
              )}
              {!isSchool && signUpData.birthDate && (
                <tr>
                  <td>Nascimento</td>
                  <td>{moment(signUpData.birthDate).format("DD/MM/YYYY")}</td>
                </tr>
              )}
              <tr>
                <td>CEP</td>
                <td>{signUpData.zip}</td>
              </tr>
              <tr>
                <td>Endereço</td>
                <td>{signUpData.address}</td>
              </tr>
              <tr>
                <td>Complemento</td>
                <td>{signUpData.complement}</td>
              </tr>
              <tr>
                <td>Bairro</td>
                <td>{signUpData.district}</td>
              </tr>
              <tr>
                <td>Cidade</td>
                <td>{signUpData.city}</td>
              </tr>
              <tr>
                <td>Estado</td>
                <td>{signUpData.state}</td>
              </tr>
            </tbody>
          </table>
          {isSchool && (
            <div>
              <h4>Dados dos alunos</h4>
              {signUpData.students.map((student, index) => (
                <div key={index}>
                  <table>
                    <tbody>
                      <tr>
                        <td>Curso</td>
                        <td>{student.courseCampus.name}</td>
                      </tr>
                      <tr>
                        <td>Campus</td>
                        <td>
                          {getCampus(student.courseCampus.campusID)?.name}
                        </td>
                      </tr>
                      <tr>
                        <td>Período</td>
                        <td>{getShift(student.courseCampus.shift)?.name}</td>
                      </tr>
                      <tr>
                        <td>Nome</td>
                        <td>{student.name}</td>
                      </tr>
                      <tr>
                        <td>Sexo</td>
                        <td>{student.sex}</td>
                      </tr>
                      <tr>
                        <td>Nascimento</td>
                        <td>
                          {moment(student.birthDate).format("DD/MM/YYYY")}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
