import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { FormMode, ingressModeCurses, SignUpData } from "../types/signUp";

export default () => {
  const [searchParams] = useSearchParams();
  const __DEV__ = process.env.NODE_ENV === "development";

  const birthDate = searchParams.get("birthDate");
  const mode = searchParams.get("mode") || "college";

  const initialValues: SignUpData = {
    email:
      searchParams.get("email") || (__DEV__ ? "mario.bonaldi@gmail.com" : ""),
    name: searchParams.get("nome") || (__DEV__ ? "Any Name" : ""),
    socialName: searchParams.get("socialName") || (__DEV__ ? "" : ""),
    phone: searchParams.get("telefone") || (__DEV__ ? "(11) 98765-4321" : ""),
    address:
      searchParams.get("address") ||
      (__DEV__ ? "R. Prof. Pedreira de Freitas, 415" : ""),
    zip: searchParams.get("zip") || (__DEV__ ? "03312-052" : ""),
    complement: searchParams.get("complement") || (__DEV__ ? "Any" : ""),
    district: searchParams.get("district") || (__DEV__ ? "Tatuapé" : ""),
    city: searchParams.get("city") || (__DEV__ ? "São Paulo" : ""),
    state: searchParams.get("state") || (__DEV__ ? "SP" : ""),
    sex: searchParams.get("sex") || (__DEV__ ? "Masculino" : ""),
    birthDate: birthDate
      ? moment(birthDate).toDate()
      : __DEV__
      ? moment("2000-01-01").toDate()
      : moment().toDate(),
    course: __DEV__ ? (mode === "school" ? "1_ano" : "design_grafico") : "",
    cpf: __DEV__ ? "659.293.960-51" : "",
    agree: false,
    ingressMode:
      searchParams.get("ingressMode") ||
      (ingressModeCurses.includes(mode) ? "Vestibular Online" : ""),
    token: "",
    isOnline: [
      "college",
      "ead-college",
      "uniten-college",
      "uniten-ead-college",
      "unitec-college",
      "unitec-ead-college",
      "technical",
    ].includes(mode),
    redaction: __DEV__
      ? "dsh h. hdsjs dsjhks hds d d d d d d d d. d d d d d d d d d d d d d d d d d d d d. dd. d d d d d d d dd. d d d d d d d d d d d d d d d d d d d d. d d d d d d d d d d d d d d d d. d d d d d d d d d d d d d d d. d d d d d d d d"
      : "",
    mode: (searchParams.get("mode") as FormMode) || "college",
    courseCampusID: __DEV__
      ? mode === "school"
        ? "1-0003_1_ano_ponte_rasa_1_manha"
        : "3-0052_design_grafico_tatuape_graduacao_manha"
      : "",
    studentBirthDates: [
      __DEV__ ? moment("2000-01-01").toDate() : moment().toDate(),
    ],
    studentNames: [__DEV__ ? "Any Student" : ""],
    studentSexes: [__DEV__ ? "Masculino" : ""],
    studentCourses: [__DEV__ ? "1_ano" : ""],
    studentCourseCampuses: __DEV__
      ? mode === "school"
        ? [
            {
              campusID: "joao_xxiii_penha",
              courseID: "pre",
              id: "1-0143_pre_joao_xxiii_penha_Pré_manha",
              name: "Pré",
              shift: "manha",
              totvsID: "1-0143",
            },
          ]
        : [
            {
              campusID: "tatuape",
              courseID: "design_grafico",
              id: "3-0052_design_grafico_tatuape_graduacao_manha",
              name: "Design Gráfico",
              shift: "manha",
              totvsID: "3-0052  ",
            },
          ]
      : [],
    studentCourseCampusIDs: [
      __DEV__
        ? mode === "school"
          ? "1-0003_1_ano_ponte_rasa_1_manha"
          : "3-0052_design_grafico_tatuape_graduacao_manha"
        : "",
    ],
  };

  return initialValues;
};
