import { useFormikContext } from "formik";
import React, { FC } from "react";
import useStyles from "../../../hooks/useStyles";
import { ingressModeCurses, SignUpData } from "../../../types/signUp";
import RecaptchaButton from "../components/RecaptchaButton";

import FieldAddress from "../fields/FieldAddress";
import FieldAgree from "../fields/FieldAgree";
import FieldBirthDate from "../fields/FieldBirthDate";
import FieldCity from "../fields/FieldCity";
import FieldComplement from "../fields/FieldComplement";
import FieldCPF from "../fields/FieldCPF";
import FieldDistrict from "../fields/FieldDistrict";
import FieldEmail from "../fields/FieldEmail";
import FieldName from "../fields/FieldName";
import FieldPhone from "../fields/FieldPhone";
import FieldSex from "../fields/FieldSex";
import FieldState from "../fields/FieldState";
import FieldZip from "../fields/FieldZip";
import FieldCampaign from "../fields/FieldCampaign";
import { useSearchParams } from "react-router-dom";
import FieldIngressMode from "../fields/FieldIngressMode";

const CollegeForm: FC = () => {
  const { isSubmitting, submitForm } = useFormikContext<SignUpData>();
  const { styles } = useStyles();
  const [searchParams] = useSearchParams();
  const mode = searchParams.get("mode") || "college";

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flex: 1,
        flexWrap: "wrap",
      }}
    >
      <FieldName />
      <FieldEmail />
      <FieldPhone />
      <FieldBirthDate />
      <FieldSex />
      <FieldCPF />
      {/* {mode === "college" && <div style={styles.thirdWidth} />} */}
      {ingressModeCurses.includes(mode) && <FieldIngressMode />}
      {!ingressModeCurses.includes(mode) && (
        <div style={styles.twoThirdsWidth} />
      )}
      <FieldZip />
      <FieldAddress />
      <FieldComplement />
      <FieldCity />
      <FieldDistrict />
      <FieldState />
      <FieldCampaign mode="college" />
      <FieldAgree />
      <RecaptchaButton isSubmitting={isSubmitting} submitForm={submitForm} />
    </div>
  );
};

export default CollegeForm;
