import {
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
} from "@mui/material";
import { useFormikContext } from "formik";
import React, { FC } from "react";

import useStyles from "../../../hooks/useStyles";
import { SignUpData } from "../../../types/signUp";

const FieldIngressMode: FC = () => {
  const { styles, isDesktop } = useStyles();
  const { values, errors, handleChange } = useFormikContext<SignUpData>();

  return (
    <FormControl error={Boolean(errors.ingressMode)} fullWidth={!isDesktop}>
      <FormLabel id="demo-simple-select-helper-label">
        Forma de ingresso
      </FormLabel>
      <RadioGroup
        defaultValue="Vestibular Online"
        name="ingressMode"
        style={{
          ...styles.twoThirdsWidth,
          ...{ display: "flex", flexDirection: "row" },
        }}
        id="ingressMode"
        value={values.ingressMode}
        onChange={handleChange}
      >
        <FormControlLabel
          value="Vestibular Online"
          control={<Radio />}
          label="Vestibular Online"
        />
        <FormControlLabel
          value="Nota do Enem"
          control={<Radio />}
          label="Nota do Enem"
        />
        <FormControlLabel
          value="Transferência"
          control={<Radio />}
          label="Transferência"
        />
      </RadioGroup>
    </FormControl>
  );
};

export default FieldIngressMode;
