import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useAuth, useFirestore } from "reactfire";

import { saveCurrent } from "../features/signUp";
import { SignUpData } from "../types/signUp";
import useCourses from "./useCourses";

import useFirebaseLog from "./useFirebaseLog";
import useStyles from "./useStyles";

export default () => {
  const firestore = useFirestore();
  const auth = useAuth();
  const { firebaseLog } = useFirebaseLog();
  const dispatch = useDispatch();
  const { isDesktop } = useStyles();
  const development = process.env.NODE_ENV === "development";
  const signUpCollection = development ? "signUpsDev" : "signUps";
  const { eventDate, eventDates } = useCourses();

  const saveData = async (data: SignUpData) => {
    firebaseLog({ action: "signUpStart", message: "", type: "log" });
    const isSchool = data.mode === "school";

    const fullData = {
      ...data,
      userId: auth.currentUser?.uid || "not signed in",
      timestamp: serverTimestamp(),
      isMobile: !isDesktop,
      event:
        data.event ||
        (eventDate
          ? {
              ...eventDate,
              timestamp: moment(eventDate.timestamp).toDate(),
              limitTimestamp: moment(eventDate.limitTimestamp).toDate(),
            }
          : eventDates
          ? {
              ...eventDates[0],
              timestamp: moment(eventDates[0].timestamp).toDate(),
              limitTimestamp: moment(eventDates[0].limitTimestamp).toDate(),
            }
          : {}),
      redaction: [],
      isOnline:
        data.ingressMode && data.ingressMode !== "Vestibular Online"
          ? false
          : data.isOnline || false,
      campusesList: isSchool
        ? data.studentCourseCampusIDs.map(
            (id, index) => data.studentCourseCampuses[index].campusID
          )
        : [data.courseCampus?.campusID || ""],
      studentBirthDates: [],
      studentSexes: [],
      studentNames: [],
      studentCourses: [],
      studentCourseCampuses: [],
      studentCourseCampusIDs: [],
      students: isSchool
        ? data.studentCourseCampusIDs.map((id, index) => ({
            name: data.studentNames[index],
            birthDate: data.studentBirthDates[index],
            sex: data.studentSexes[index],
            course: data.studentCourses[index],
            campus: data.studentCourseCampusIDs[index],
            courseCampus: data.studentCourseCampuses[index],
            courseCampusID: data.studentCourseCampusIDs[index],
          }))
        : [
            {
              name: data.name,
              birthDate: data.birthDate,
              sex: data.sex,
              course: data.course,
              courseCampus: data.courseCampus,
              courseCampusID: data.courseCampusID,
            },
          ],
    };

    console.log(fullData);

    const signupsCollection = collection(firestore, signUpCollection);
    const result = await addDoc(signupsCollection, fullData);

    if (result.id) {
      dispatch(
        saveCurrent({
          ...fullData,
          timestamp: moment().toDate().toISOString(),
          id: result.id,
          birthDate: moment(fullData.birthDate).toDate().toLocaleDateString(),
          students: fullData.students.map((student) => ({
            ...student,
            birthDate: moment(student.birthDate).toDate().toLocaleDateString(),
          })),
        })
      );
      firebaseLog({ action: "signUpEnd", message: "", type: "log" });
      return result.id;
    }

    firebaseLog({
      action: "signUpSaveDataError",
      message: "Erro ao salvar a inscrição",
      type: "error",
    });
    return null;
  };

  return { saveData };
};
