import React, { FC } from "react";
import { COLORS } from "../../../../constants/colors";

import useStyles from "../../../../hooks/useStyles";
import useBanners from "../../../../hooks/useBanners";
import useCourses from "../../../../hooks/useCourses";

type BannerProps = {
  mode: string;
};

const Banner: FC<BannerProps> = ({ mode }) => {
  const { isDesktop } = useStyles();
  const { banners } = useBanners(mode);

  const getImg = () => {
    switch (isDesktop) {
      case false:
        return banners ? banners.mobile : undefined;
      default:
        return banners ? banners.desktop : undefined;
    }
  };

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: COLORS.primary,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <img src={getImg()} style={!isDesktop ? { width: "100%" } : {}} />
    </div>
  );
};

export default Banner;
