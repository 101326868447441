import { CourseCampus } from "./courseCampus";
import { EventDate } from "./eventDate";

export const ingressModeCurses = [
  "college",
  "ead-college",
  "uniten-college",
  "uniten-ead-college",
  "unitec-college",
  "unitec-ead-college",
];

export type FormMode =
  | "school"
  | "college"
  | "post"
  | "ead-post"
  | "ead-college"
  | "ead-eja"
  | "technical"
  | "uniten-college"
  | "uniten-ead-college"
  | "unitec-college"
  | "unitec-ead-college"
  | "unitec-ead-post";

export type SignUpData = {
  cpf: string;
  name: string;
  socialName: string;
  email: string;
  phone: string;
  address: string;
  zip: string;
  complement: string;
  district: string;
  city: string;
  state: string;
  sex: string;
  birthDate?: Date;
  course: string;
  courseCampusID: string;
  courseCampus?: CourseCampus;
  agree: boolean;
  presential?: boolean;
  redaction?: string;
  token: string;
  studentBirthDates: Date[];
  studentNames: string[];
  studentSexes: string[];
  studentCourses: string[];
  studentCourseCampuses: CourseCampus[];
  studentCourseCampusIDs: string[];
  mode: FormMode;
  isOnline?: boolean;
  event?: EventDate;
  campaign?: string;
  ingressMode?: string;
};

export type CampaignData = {
  id: string;
  name: string;
};

export type SignUpLocalData = SignUpData & {
  id: string;
  date: Date;
  userId: string;
  students: {
    name: string;
    birthDate: Date;
    sex: string;
    course: string;
    courseCampus: CourseCampus;
    courseCampusID: string;
  }[];
};

export type SignUpErrors = {
  name?: string;
  socialName?: string;
  email?: string;
  phone?: string;
  address?: string;
  complement?: string;
  district?: string;
  city?: string;
  state?: string;
  zip?: string;
  sex?: string;
  birthDate?: string;
  course?: string;
  courseCampusID?: string;
  agree?: string;
  redaction?: string;
  token?: string;
  studentBirthDates?: string[];
  studentNames?: string[];
  studentSexes?: string[];
  studentCourses?: string[];
  studentCourseCampuses?: string[];
  studentCourseCampusIDs?: string[];
  mode?: string;
  isOnline?: string;
  cpf?: string;
};
